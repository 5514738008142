import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { DatabaseService } from "../database.service";
import { AlertController } from '@ionic/angular';
import { Router, ActivatedRoute } from '@angular/router';
import { ThermalPrinterPlugin } from 'thermal-printer-cordova-plugin/src';
import { BluetoothSerial } from '@awesome-cordova-plugins/bluetooth-serial/ngx';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {Observable} from 'rxjs/Observable';

declare let ThermalPrinter: ThermalPrinterPlugin;

@Component({
  selector: 'app-addfoto',
  templateUrl: './addfoto.page.html',
  styleUrls: ['./addfoto.page.scss'],
})
export class AddfotoPage implements OnInit {

  @Input() sbmskfile: any;
  @Input() sbnoso: string;
  @Input() sbsodate: string;

  sbcash: number;
  sbchange: number;

  msg: any;
  mskfile: any;

  totalstring: string;
  grandstring: string;
  cashstring: string;
  kembalistring: string;

  newgrand: any;
  newtotal: any;
  newcash: any;
  newkembali: any;

  constructor(
    private modalController: ModalController,public http: HttpClient, public database: DatabaseService,public alertController: AlertController,private router: Router,
    private actRoute: ActivatedRoute,private bluetoothSerial: BluetoothSerial
  ) { }
  


  ionViewWillEnter() {

              
        this.mskfile = this.sbmskfile;



    }

   
  async closeModel() {
    const close: string = "Modal Removed";
    await this.modalController.dismiss(close);
  }


  async presentAlert(hdr:any,msg: any) {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: hdr,
      subHeader: '',
      message: msg,
      buttons: ['OK']
    });

    await alert.present();

    const { role } = await alert.onDidDismiss();
    console.log('onDidDismiss resolved with role', role);
  }


  async closeModal(){

   
        await this.modalController.dismiss(close);

        this.router.navigate(['/absen']);

     
  }


  
  ngOnInit() { }


}
